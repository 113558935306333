<template>
    <div>
        <nav class="fixed-top d-flex justify-content-between">
            <div class="nav-logo d-inline-flex align-items-center justify-content-center">
                <router-link to="/"
                    @click="menuOpen = false"
                >
                    <img src="@/assets/img/logo.png" alt="Normandie Merule Logo">
                </router-link>
            </div>
            <div class="nav-info d-inline-flex align-items-center justify-content-center">
                <div class="burger-icon" :class="{'clicked': menuOpen}" @click.prevent="openMenu">
                    <span></span>
                </div>
            </div>
        </nav>
        <div class="info-panel d-flex flex-column justify-content-between" :class="{'open': menuOpen }">
            <a
                class="nav-item d-inline-flex align-items-center justify-content-center"
                v-for="(item, idx) in menu"
                :key="idx"
                v-scroll-to="{
                    el: `#${item.to}`,
                    duration: 300,
                    offset: -50
                }"
                href="#"
                @click="menuOpen = false"
            >
                {{ item.label }}
            </a>
        </div>
    </div>
</template>

<script>
export default {
    name: "NavigationMobile",

    data() {
        return {
            menuOpen: false,
            menu: [
                {
                    label: "À propos",
                    to: "about"
                },
                {
                    label: "Services",
                    to: "services"
                },
                {
                    label: "Nos valeurs",
                    to: "values"
                },
                {
                    label: "Contact",
                    to: "contact"
                }
            ]
        }
    },
    
    methods: {
        openMenu() {
            if (this.panelOpen) {
                this.panelOpen = false;
            }
            this.menuOpen = !this.menuOpen;
        }
    }
}
</script>

<style lang="scss" scoped>
nav {
    height: 55px;
    background-color: $white;
    padding: 0 10px;

    .nav-logo {
        img {
            max-width: 100%;
            height: 45px;
        }
    }

    .nav-info {
        .burger-icon {
            display: inline-block;
            position: relative;
            width: 48px;
            height: 48px;
            line-height: 48px;
            cursor: pointer;
            color: $black;
            background-color: transparent;
            border: none;
            cursor: pointer;

            span {
                top: 50%;
                position: relative;
                display: block;
                margin: auto;
                transition: .3s all ease;

                &, &::before, &::after {
                    width: 22px;
                    height: 2px;
                    background-color: $black;
                    backface-visibility: hidden;
                    will-change: transform;
                }

                &::before, &::after {
                    -webkit-transition-duration: 0.22s, 0.22s;
                    transition-duration: 0.22s, 0.22s;
                    -webkit-transition-delay: 0.22s, 0s;
                    transition-delay: 0.22s, 0s;
                    -webkit-transition-property: top, -webkit-transform;
                    transition-property: top, transform;

                    content: "";
                    position: absolute;
                    left: 0;
                    transition: .3s all ease;
                }

                &::after {
                    top: 6px;
                }

                &::before {
                    top: -6px;
                }
            }

            &.clicked {
                span {
                    background-color: $white;

                    &::before, &::after {
                        top: 0;
                        -webkit-transition-delay: 0s, 0.22s;
                        transition-delay: 0s, 0.22s;
                    }

                    &::before {
                        transform: rotate(45deg);
                    }

                    &::after {
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }
}

.info-panel {
    position: fixed;
    background-color: $white;
    top: -150px;
    left: 0;
    z-index: 10;
    width: 100vw;
    transition: 450ms ease-in-out;
    padding: 0 10px;

    &.open {
        top: 55px;
    }

    .nav-item {
        text-transform: uppercase;
        color: $black;
        font-weight: bold;
        padding: 10px 0;
    }
}
</style>