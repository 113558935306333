export default {
    namespaced: true,

    state: {},

    getters: {},

    actions: {},

    mutations: {}
}