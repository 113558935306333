<template>
    <footer class="footer text-center text-lg-start">
        <!-- Section: Links  -->
        <section class="py-3">
            <div class="container text-center text-md-start mt-5">
                <div class="row mt-3">
                    <!-- Company description -->
                    <div class="col-md-3">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title">NORMANDIE MERULE</h5>
                                <p class="card-text">Votre expert dans le traitement de la mérule en Normandie, à Caen et à Rouen. Diagnostic, traitement, spécialiste de la mérule et champignons lignivores.</p>
                            </div>
                        </div>
                    </div>

                    <!-- Contact -->
                    <div class="col-md-4 px-5 mt-md-0 my-5">
                        <h6 class="text-uppercase fw-bold mb-4">
                            Contact
                        </h6>
                        <p>
                            <span class="mdi icon mdi-map-marker-circle"></span>
                            <a href="https://www.google.fr/maps/dir//Saint-Christophe,+61230+Saint-Evroult-de-Montfort/@48.8032659,0.2968022,15z/data=!4m9!4m8!1m0!1m5!1m1!1s0x47e1f37c112fa3fd:0xd5a9d73de09b026e!2m2!1d0.305557!2d48.803226!3e0" target="_blank">Saint Christophe <br> 61230 SAINT EVROULT DE MONTFORT</a>
                        </p>
                        <p>
                            <span class="mdi icon mdi-at"></span>
                            <a href="mailto:androuardb@gmail.com">androuardb@gmail.com</a>
                        </p>
                        <p>
                            <span class="mdi icon mdi-phone-outline"></span>
                            <a href="tel:0611085208">06 11 08 52 08</a>
                        </p>
                    </div>

                    <!-- Map -->
                    <div class="col-md-5">
                        <h6 class="text-uppercase fw-bold mb-4">
                            <Map />
                        </h6>
                    </div>
                </div>
            </div>
        </section>

        <!-- Copyright -->
        <div class="text-center p-4" style="background-color: rgba(0, 0, 0, 0.05);">
            Tous droits réservés. &copy; {{ new Date().getFullYear() }} Normandie Mérule. <router-link to="/mentions-legales">Mentions légales</router-link>
        </div>
    </footer>
</template>

<script>
import Map from '@/components/Commons/Map'

export default {
    name: "Footer",

    components: {
        Map
    }
}
</script>

<style lang="scss" scoped>
.footer {
    .card {
        padding: 75px 32px;
        padding-bottom: 60px;
        background-color: inherit;
        box-shadow: 0ch;
        border: none;
        border-radius: 0;
        border-top: 4px solid $primary;
        box-shadow: 0px 18px 38px 0px rgba(161, 171, 220, 0.3);

        p {
            text-align: justify;
        }
    }

    span {
        margin-right: 10px;
        font-size: 14pt;
    }
}
</style>