<template>
    <nav :class="{'bg-color-fill': scrollPosition > 50}">
        <div class="container d-flex justify-content-between align-items-stretch">
            <div class="nav-logo">
                <router-link to="/">
                    <img src="@/assets/img/logo.png" alt="Normandie Merule Logo">
                </router-link>
            </div>
            <div class="nav-content">
                <a
                    class="nav-item d-inline-flex align-items-center justify-content-center"
                    v-for="(item, idx) in menu"
                    :key="idx"
                    v-scroll-to="{
                        el: `#${item.to}`,
                        duration: 200,
                        offset: -135
                    }"
                    href="#"
                >
                    {{ item.label }}
                </a>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: "Navigation",

    data() {
        return {
            scrollPosition: null,
            menu: [
                {
                    label: "À propos",
                    to: "about"
                },
                {
                    label: "Services",
                    to: "services"
                },
                {
                    label: "Nos valeurs",
                    to: "values"
                },
                {
                    label: "Contact",
                    to: "contact"
                },
            ]
        }
    },

    mounted() {
        window.addEventListener('scroll', this.updateScroll);
    },
    
    methods: {
        updateScroll() {
            this.scrollPosition = window.scrollY
        }
    }
}
</script>

<style lang="scss" scoped>
nav {
    padding: 25px;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: rgba(255, 255, 255, 0.8);
    transition: 450ms ease-in-out;

    &.bg-color-fill {
        background-color: rgba(255, 255, 255, 0.8);
        padding: 10px;
    }
    
    .nav-logo {
        max-width: 250px;

        img {
            max-width: 100%;
        }
    }

    .nav-content {
        .nav-item {
            height: 100%;
            text-transform: uppercase;
            color: $grey;
            font-weight: bold;
            min-width: 120px;
            border-bottom: 3px solid transparent;
            transition: 250ms ease-in-out;

            &:not(:last-of-type) {
                margin-right: 30px;
            }

            &.router-link-exact-active {
                color: $primary;
                border-color: $primary;
            }

            &:hover {
                color: $primary !important;
                text-decoration: none;
                border-color: $primary;
            }
        }
    }
}
</style>