<template>
	<div id="app">
		<Navigation v-if="$mq === 'desktop'" />
		<NavigationMobile v-else />
		<transition name="fade" mode="out-in">
			<router-view></router-view>
		</transition>
		<Footer id="equipements" />
	</div>
</template>

<script>
import Navigation from '@/components/Navigation/Navigation'
import NavigationMobile from '@/components/Navigation/NavigationMobile'
import Footer from '@/components/Footer'

export default {
	name: "App",

	components: {
		Navigation,
		NavigationMobile,
		Footer
	},
	
	computed: {
		mq() {
			return this.mq.current
		}
	}
}
</script>

<style src="./assets/scss/main.scss" lang="scss"></style>

<style lang="scss"></style>
