import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'animate.css';

import VueScrollTo from 'vue-scrollto'

import VueMq from 'vue-mq'

import VueTabs from 'vue-nav-tabs'

import Vuelidate from 'vuelidate'

import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import VueAnimate from 'vue-animate-scroll'

Vue.config.productionTip = false

Vue.use(VueScrollTo)

Vue.use(VueMq, {
  breakpoints: {
    mobile: 576,
    tablet: 1025,
    desktop: Infinity,
  }
})

Vue.use(VueTabs)

Vue.use(Vuelidate)

Vue.use(VueLoading)

Vue.use(VueAnimate)

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
