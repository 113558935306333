<template>
    <div id="map">
    </div>
</template>

<script>
import data from '@/data/normandie.json'
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

export default {
    name: "Map",

    data() {
        return {
            map: null,
            mapOptions: {
                closePopupOnClick: false,
                touchZoom: false,
                scrollWheelZoom: false
            }
        }

    },
    mounted() {
        // setting zoom based on breakpoints
        let zoom = 6;
        
        this.map = L.map('map', this.mapOptions);
        this.map.setView([49, 0], zoom);
        L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png', {
            attribution:'&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attributions">CARTO</a>',
            subdomains: 'abcd',
            maxZoom: 20,
            minZoom: 5
        }).addTo(this.map);
        this.setPolygon();
    },

    methods: {
        setPolygon() {            
            const geoData = data.geometry.coordinates;
            const latlngs = geoData[0]
            let invertedLatLngs = latlngs.map(latlong => {
                return [latlong[1], latlong[0]]
            })
            L.polygon(invertedLatLngs, {color: '#8abed5'}).addTo(this.map);
        }
    }
}
</script>

<style lang="scss">
#map {
    width: 100%;
    height: 400px;

    .mapIcon {
        color: $primary;
    }

    .leaflet-popup-content {
        a {
            color: inherit;
            text-decoration: none;
            transition: 250ms ease-in-out;
        }

        &:hover {
            a {
                color: $primary;
            }
        }
    }
}
</style>